import React from 'react';

const MapSection = () => {
  return (
    <section className="map-section">
      <iframe
        className="map w-100"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3428.4847382867165!2d76.12661237549685!3d30.760971874570718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39100db6263a889b%3A0x48299c027b2ec396!2sNew%20J.D%20Tour%20Travel%20Bhourla!5e0!3m2!1sen!2sin!4v1729233401618!5m2!1sen!2sin"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Map"
      />
    </section>
  );
};

export default MapSection;
