import React, { useState } from "react";
import { FaMapMarkerAlt, FaMobile } from "react-icons/fa";

const BookingSection = () => {
  const taxiOptions = [
    "Hybrid Taxi",
    "Town Taxi",
    "SUV Taxi",
    "Limousine Taxi",
  ];
  const rideTypes = ["Single", "Family"];

  const [formData, setFormData] = useState({
    taxiType: "",
    startDestination: "",
    endDestination: "",
    rideType: "Single",
    name: "",
    email: "",
    phoneNo: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { taxiType, startDestination, endDestination, name, email, phoneNo } =
      formData;

    let formErrors = {};
    if (!taxiType) formErrors.taxiType = "Please choose a taxi type";
    if (!startDestination)
      formErrors.startDestination = "Start destination is required";
    if (!endDestination)
      formErrors.endDestination = "End destination is required";
    if (!name) formErrors.name = "Name is required";
    if (!email) formErrors.email = "Email is required";
    if (!phoneNo) formErrors.phoneNo = "Phone number is required";

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    setErrors({});
    setIsSubmitted(true);
  };

  return (
    <section id="contact" className="booking-section">
      <div className="bg bg-image"></div>
      <div className="bg-overlay"></div>
      <div className="auto-container">
        <div className="booking-form">
          <div className="sec-title">
            <h2 className="letters-slide-up text-split">Book Your Ride!</h2>
            <div className="text">
              To get the ride of your taxi, please select from the following:
            </div>
          </div>

          {!isSubmitted ? (
            <form method="get" onSubmit={handleSubmit}>
              <div className="row">
                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                  <select
                    className="custom-select"
                    name="taxiType"
                    value={formData.taxiType}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Choose Taxi Type</option>
                    {taxiOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {errors.taxiType && (
                    <small className="text-danger">{errors.taxiType}</small>
                  )}
                </div>

                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="input-outer">
                    <input
                      type="text"
                      name="startDestination"
                      placeholder="Start Destination"
                      value={formData.startDestination}
                      onChange={handleChange}
                      required
                    />
                    <FaMapMarkerAlt className="icon" />
                    {errors.startDestination && (
                      <small className="text-danger">
                        {errors.startDestination}
                      </small>
                    )}
                  </div>
                </div>

                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="input-outer">
                    <input
                      type="text"
                      name="endDestination"
                      placeholder="End Destination"
                      value={formData.endDestination}
                      onChange={handleChange}
                      required
                    />
                    <FaMapMarkerAlt className="icon" />
                    {errors.endDestination && (
                      <small className="text-danger">
                        {errors.endDestination}
                      </small>
                    )}
                  </div>
                </div>

                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                  <div className="boxed-outer-box">
                    {rideTypes.map((type, index) => (
                      <div className="boxed" key={index}>
                        <input
                          type="radio"
                          id={type}
                          name="rideType"
                          value={type}
                          checked={formData.rideType === type}
                          onChange={handleChange}
                        />
                        <label htmlFor={type}>{type}</label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <input
                    type="text"
                    name="name"
                    placeholder="Complete Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  {errors.name && (
                    <small className="text-danger">{errors.name}</small>
                  )}
                </div>

                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  {errors.email && (
                    <small className="text-danger">{errors.email}</small>
                  )}
                </div>

                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                  <div className="input-outer">
                    <input
                      type="text"
                      name="phoneNo"
                      placeholder="Phone No"
                      value={formData.phoneNo}
                      onChange={handleChange}
                      required
                    />
                    <FaMobile className="icon" />
                    {errors.phoneNo && (
                      <small className="text-danger">{errors.phoneNo}</small>
                    )}
                  </div>
                </div>

                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                  <button type="submit" className="theme-btn btn-style-one">
                    <span className="btn-title">Find a Taxi</span>
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <>
              <div className="alert alert-success">
                Your taxi has been booked successfully!
              </div>
              <div className="map-container">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3428.4847382867165!2d76.12661237549685!3d30.760971874570718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39100db6263a889b%3A0x48299c027b2ec396!2sNew%20J.D%20Tour%20Travel%20Bhourla!5e0!3m2!1sen!2sin!4v1729233401618!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default BookingSection;
