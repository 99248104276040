import React from "react";
import Range from "../../../../images/jd-tour/range-rower.jpeg";
import Audi from "../../../../images/resource/audi.jpeg";
import Benz from "../../../../images/resource/benz.jpeg";
import News from "../../../../images/jd-tour/owner2.png";
import { FaComment } from "react-icons/fa";

const newsData = [
  {
    id: 1,
    image: Range,
    date: "January 9, 2024",
    comments: 14,
    title: "How to start initiating an startup in few",
    author: "Himmat Singh Dhillon",
    authorImage: News,
    link: "#news",
  },
  {
    id: 2,
    image: Audi,
    date: "January 9, 2024",
    comments: 14,
    title: "Tensive quality vectors life through strategies",
    author: "Himmat Singh Dhillon",
    authorImage: News,
    link: "#news",
  },
  {
    id: 3,
    image: Benz,
    date: "January 9, 2024",
    comments: 14,
    title: "How to start initiating an startup in few",
    author: "Himmat Singh Dhillon",
    authorImage: News,
    link: "#news",
  },
];

const NewsSection = () => {
  return (
    <section id="news" className="news-section">
      <div className="auto-container">
        <div className="sec-title text-center">
          <span className="sub-icon"></span>
          <span className="sub-title">News & Updates</span>
          <h2 className="letters-slide-up text-split">Recent Articles</h2>
        </div>
        <div className="row">
          {newsData.map((news) => (
            <div
              className="news-block col-lg-4 col-md-6"
              key={news.id}
              data-aos="fade-up"
            >
              <div className="inner-box">
                <figure className="image">
                  <a href={news.link}>
                    <img src={news.image} alt="news" />
                    <img src={news.image} alt="news" />
                  </a>
                </figure>
                <div className="content">
                  <ul className="post-meta">
                    <li>{news.date}</li>
                    <li>
                      <FaComment /> {news.comments}
                    </li>
                  </ul>
                  <h4 className="title">
                    <a href={news.link}>{news.title}</a>
                  </h4>
                  <div className="author-box">
                    <img src={news.authorImage} alt="author" />
                    <h6 className="name">
                      by
                      <span>
                        <a href={news.link}>{news.author}</a>
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="btn-box text-center">
          <a href="#news" className="theme-btn btn-style-one">
            <span className="btn-title">View All News</span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default NewsSection;
