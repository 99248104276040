import React from "react";

const breadcrumbData = [
  { title: "Home", link: "index.html" },
  { title: "Contact", link: "#" },
];

const PageTitle = () => {
  return (
    <section className="page-title">
      <div className="auto-container">
        <div className="title-outer text-center">
          <h1 className="title">New J.D Tour Travel Bhourla</h1>
          <ul className="page-breadcrumb">
            {breadcrumbData.map((item, index) => (
              <li key={index}>
                <a href={item.link}>{item.title}</a>
                {index < breadcrumbData.length - 1 && <span> &gt; </span>}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default PageTitle;
