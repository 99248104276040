import React, { useState } from "react";
import "../Team/Faq.scss";
import { FaPlus, FaMinus, FaSearch } from "react-icons/fa";

const faqs = [
  {
    question: "What areas do you provide taxi services in?",
    answer:
      "At New JD Tour Travel Bhourla, we proudly serve in Ludhiana, Delhi and surrounding areas, including local rides, outstation trips, and airport transfers. Whether you need transportation within the city or to nearby towns, we've got you covered.",
  },
  {
    question: "How much does a taxi ride cost?",
    answer:
      "Our pricing is competitive and transparent, with rates depending on the distance and type of service. For local rides, outstation trips, or airport transfers, please contact us for a personalized quote, and we’ll be happy to assist you.",
  },
  {
    question: "Can I schedule a taxi in advance?",
    answer:
      "Yes, you can easily schedule a taxi in advance with New JD Tour Travel Bhourla. Simply call us at +91-9465349307 or book online, and we’ll ensure your taxi is ready at the time and location you specify.",
  },
  {
    question: "Do you offer airport transfer services?",
    answer:
      "Absolutely! We provide reliable airport pickup and drop services to and from your airport, ensuring you reach your destination on time and stress-free, 24/7.",
  },
  {
    question: "Are your taxi drivers licensed and insured?",
    answer:
      "Yes, all drivers and our taxis at New JD Tour Travel Bhourla are fully licensed, experienced, and insured. Your safety and comfort are our top priorities, so you can trust us for a safe and professional ride.",
  },
];

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section id="faq" className="faq-section">
      <div className="auto-container">
        <div className="sec-title text-center">
          <span className="sub-icon"></span>
          <span className="sub-title">FAQs</span>
          <h2 className="letters-slide-up text-split">
            Questions Regarding Rental Requirements
          </h2>
        </div>
        <div className="faq-container">
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <div className="faq-question" onClick={() => toggleFAQ(index)}>
                <span className="faq-search-icon">
                  <FaSearch />
                </span>
                <h3>{faq.question}</h3>
                <span className="faq-icon">
                  {activeIndex === index ? <FaMinus /> : <FaPlus />}
                </span>
              </div>
              {activeIndex === index && (
                <div className="faq-answer">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
