import React, { useState } from "react";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

const contactDetailsData = [
  {
    icon: <FaPhoneAlt />,
    title: "Have any question?",
    description: (
      <a href="tel:9465349307">
        <span>call</span> +91-9465349307
      </a>
    ),
  },
  {
    icon: <FaEnvelope />,
    title: "Write email",
    description: (
      <a href="mailto:newjdtourtravel@gmail.com">
        <span>newjdtourtravel@gmail.com</span>
      </a>
    ),
  },
  {
    icon: <FaMapMarkerAlt />,
    title: "Visit anytime",
    description: <span>Village - Bhourla (Near Chawa), Tehsil - Samrala, Ludhiana, India, 141412</span>,
  },
];
const sectionContent = [
  {
    subtitle: "Send us email",
    title: "Feel free to write",
    text: "If you have any questions, feel free to reach out to us through the form.",
  },
];
const sectionContent2 = [
  {
    subtitle: "Need any help?",
    title: "Get in touch with us",
    text: "We’re here to help! Whether you need to book a ride, inquire about our services, or have any questions, feel free to reach out to us.",
  },
];

const ContactSection = () => {
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    const name = document.getElementById("form_name").value;
    const email = document.getElementById("form_email").value;
    const subject = document.getElementById("form_subject").value;
    const phone = document.getElementById("form_phone").value;
    const message = document.getElementById("form_message").value;

    let errors = {};
    if (!name) errors.name = "Name is required";
    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
    }
    if (!subject) errors.subject = "Subject is required";
    if (!phone) errors.phone = "mobile number is required";
    if (!message) errors.message = "Message is required";

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    alert(
      `Message sent successfully!\n\nDetails:\nName: ${name}\nEmail: ${email}\nSubject: ${subject}\nPhone: ${phone}\nMessage: ${message}`
    );

    document.getElementById("contact_form").reset();
    setErrors({});
  };
  const handleReset = () => {
    document.getElementById("contact_form").reset();
    setErrors({});
  };

  return (
    <section className="contact-details">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6">
            {sectionContent.map((item, index) => (
              <div key={index} className="sec-title">
                <span className="sub-title">{item.subtitle}</span>
                <h2>{item.title}</h2>
                <div className="text">{item.text}</div>
              </div>
            ))}

            <form
              id="contact_form"
              name="contact_form"
              action="https://html.kodesolution.com/2024/taxix-html/includes/sendmail.php"
              method="post"
              onSubmit={handleSubmit}
            >
              <div className="row">
                <div className="col-sm-6">
                  <div className="mb-3">
                    <input
                      id="form_name"
                      className="form-control"
                      type="text"
                      placeholder="Enter Name"
                    />
                    {errors.name && (
                      <small className="text-danger">{errors.name}</small>
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-3">
                    <input
                      id="form_email"
                      className="form-control"
                      type="email"
                      placeholder="Enter Email"
                    />
                    {errors.email && (
                      <small className="text-danger">{errors.email}</small>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="mb-3">
                    <input
                      id="form_subject"
                      className="form-control"
                      type="text"
                      placeholder="Enter Subject"
                    />
                    {errors.subject && (
                      <small className="text-danger">{errors.subject}</small>
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-3">
                    <input
                      id="form_phone"
                      className="form-control"
                      type="text"
                      placeholder="Enter Phone"
                    />
                    {errors.phone && (
                      <small className="text-danger">{errors.phone}</small>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <textarea
                  id="form_message"
                  className="form-control"
                  rows="6"
                  placeholder="Enter Message"
                ></textarea>
                {errors.message && (
                  <small className="text-danger">{errors.message}</small>
                )}
              </div>
              <div className="mb-5">
                <button
                  type="submit"
                  className="theme-btn btn-style-one mb-3 mb-sm-0 contact-btn"
                >
                  <span className="btn-title ">Send message</span>
                </button>
                <button
                  onClick={handleReset}
                  type="reset"
                  className="theme-btn btn-style-one bg-theme-color5 contact-btn"
                >
                  <span className="btn-title ">Reset</span>
                </button>
              </div>
            </form>
          </div>

          <div className="col-xl-5 col-lg-6">
            <div className="contact-details__right">
              {sectionContent2.map((item, index) => (
                <div key={index} className="sec-title">
                  <span className="sub-title">{item.subtitle}</span>
                  <h2>{item.title}</h2>
                  <div className="text">{item.text}</div>
                </div>
              ))}

              <ul className="list-unstyled contact-details__info">
                {contactDetailsData.map((item, index) => (
                  <li
                    key={index}
                    className="d-flex  d-sm-flex  align-items-sm-center"
                  >
                    <div className="icon">
                      <span>{item.icon}</span>
                    </div>
                    <div className="text ml-xs--0 mt-xs-10">
                      <h4>{item.title}</h4>
                      {item.description}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
