import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Pricing1 from "../../../../images/jd-tour/swift.jpeg";
import Pricing2 from "../../../../images/jd-tour/honda-amaze.jpeg";
import Pricing3 from "../../../../images/jd-tour/Ertiga.jpeg";
import Pricing4 from "../../../../images/jd-tour/Innova.jpeg";
import Pricing5 from "../../../../images/jd-tour/tempu-traveller.jpeg";
import Pricing6 from "../../../../images/jd-tour/urbannia.jpeg";
import { FaCar, FaMapMarkerAlt, FaUsers } from "react-icons/fa";

const PricingSection = () => {
  const pricingPlans = [
    {
      image: Pricing1,
      carName: "swift",
      perKm: "17 INR (Negotiable)",
      description:
        "Rest of the things we can discuss on call. Please connect with us.",
    },
    {
      image: Pricing2,
      carName: "Honda amaze",
      perKm: "17 INR (Negotiable)",
      description:
        "Rest of the things we can discuss on call. Please connect with us.",
    },
    {
      image: Pricing3,
      carName: "Ertiga",
      perKm: "17 INR (Negotiable)",
      description:
        "Rest of the things we can discuss on call. Please connect with us.",
    },
    {
      image: Pricing4,
      carName: "Innova",
      perKm: "17 INR (Negotiable)",
      description:
        "Rest of the things we can discuss on call. Please connect with us.",
    },
    {
      image: Pricing5,
      carName: "tempu traveller",
      perKm: "17 INR (Negotiable)",
      description:
        "Rest of the things we can discuss on call. Please connect with us.",
    },
    {
      image: Pricing6,
      carName: "urbannia",
      perKm: "17 INR (Negotiable)",
      description:
        "Rest of the things we can discuss on call. Please connect with us.",
    },
  ];

  const options = {
    loop: true,
    margin: 10,
    nav: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <section id="pricing" className="pricing-section">
      <div className="auto-container">
        <div className="bg bg-pattern-5"></div>
        <div className="sec-title text-center">
          <span className="sub-icon"></span>
          <span className="sub-title">Choose Your Taxi</span>
          <h2 className="letters-slide-up text-split">Choose Your Ride!</h2>
        </div>
        <div className="outer-box" data-aos="fade-left">
          <OwlCarousel className="pricing-carousel owl-theme" {...options}>
            {pricingPlans.map((plan, index) => (
              <div className="pricing-block" key={index}>
                <div className="inner-box">
                  <div className="image-box">
                    <figure className="image">
                      <img src={plan.image} alt={plan.carName} />
                    </figure>
                    <div className="icon-car">
                      <FaCar />
                    </div>
                  </div>
                  <div className="content">
                    <div className="car-detail">
                      <h4 className="car-name">{plan.carName}</h4>
                    </div>
                    <ul className="feature-list">
                      <li className="colored">
                        Price Per KM:
                        <span className="price">{plan.perKm}</span>
                      </li>
                      <li >
                      <span className="description-2">{plan.description}</span>
                      </li>
                    </ul>
                    <div className="btn-box">
                      <a
                        href="#contact"
                        className="theme-btn btn-style-two hover-light"
                      >
                        <span className="btn-title">Book a Taxi</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
