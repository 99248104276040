import React, { useEffect, useState, useRef } from "react";
import Img from "../../../images/logo-2.png";
import Img2 from "../../../images/logo-black.png";
import {
  FaMobileAlt,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaBars,
  FaTimes,
  FaSearch,
  FaPhoneAlt,
  FaMapMarker,
  FaAngleDown,
} from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

const socialLinks = [
  {
    href: "https://www.facebook.com/newjdtourtravelsbhourla",
    icon: <FaFacebookF />,
  },
  {
    href: "https://www.instagram.com/new_j_d_tour_travels_bhourla/",
    icon: <FaInstagram />,
  },
  { href: "https://www.youtube.com/@himmatdhillon7788", icon: <FaYoutube /> },
];

const navLinks = [
  { href: "#home", label: "Home" },
  { href: "#tour", label: "Tour" },
  { href: "#service", label: "Services" },
  { href: "#about", label: "About" },
  { href: "#contact", label: "Book Taxi" },
  { href: "#pricing", label: "Pricing" },
  { href: "#faq", label: "FAQs" },
  { href: "#news", label: "News" },
  { href: "/contact", label: "Contact us" },
];
const navLinks2 = [
  { href: "#home", label: "Home" },
  { href: "#tour", label: "Tour" },
  { href: "#service", label: "Services" },
  { href: "#about", label: "About" },
  { href: "#pricing", label: "Pricing" },
  { href: "#faq", label: "FAQs" },
  { href: "#news", label: "News" },
  { href: "/contact", label: "Contact us" },
];

const contactInfo = [
  {
    icon: <FaPhoneAlt />,
    title: "Call Now",
    text: "+92 (8800) - 98670",
    href: "tel:9465349307",
  },
  {
    icon: <FaEnvelope />,
    title: "Send Email",
    text: "newjdtourtravel@gmail.com",
    href: "mailto:newjdtourtravel@gmail.com",
  },
  {
    icon: <FaMapMarker />,
    title: "Address",
    text: "66 Broklyant, New York India 3269",
  },
];

const Header = () => {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const [mobileSearchActive, setMobileSearchActive] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isFixedHeader, setIsFixedHeader] = useState(false);
  const stickyHeaderRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
    if (!isMobileMenuVisible) {
      document.body.classList.add("mobile-menu-visible");
    } else {
      document.body.classList.remove("mobile-menu-visible");
    }
  };
  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const toggleSearch = () => {
    setMobileSearchActive(!mobileSearchActive);
    if (!mobileSearchActive) {
      document
        .querySelector(".main-header")
        .classList.add("moblie-search-active");
    } else {
      document
        .querySelector(".main-header")
        .classList.remove("moblie-search-active");
    }
  };

  const handleScroll = () => {
    const windowPos = window.scrollY;
    if (windowPos > 100) {
      setIsFixedHeader(true);
    } else {
      setIsFixedHeader(false);
    }
  };

  const handleNavigation = (path) => {
    if (path.startsWith("#")) {
      const targetElement = document.querySelector(path);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      } else {
        navigate("/");
        setTimeout(() => {
          const element = document.querySelector(path);
          if (element) element.scrollIntoView({ behavior: "smooth" });
        }, 0);
      }
    } else {
      navigate(path);
    }
  };
  const activateMenuItemOnReach = () => {
    const cur_pos = window.scrollY + 2;
    const nav_height = stickyHeaderRef.current?.offsetHeight || 0;
    document.querySelectorAll("section").forEach((section) => {
      const top = section.offsetTop - nav_height - 80;
      const bottom = top + section.offsetHeight;

      if (cur_pos >= top && cur_pos <= bottom) {
        document
          .querySelectorAll(".onepage-nav a")
          .forEach((a) => a.parentNode.classList.remove("current", "active"));
        section.classList.add("current", "active");
        document
          .querySelector(`.onepage-nav a[href="#${section.id}"]`)
          ?.parentNode.classList.add("current", "active");
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="main-header header-style-one">
      <div className="header-top">
        <div className="auto-container">
          <div className="inner-container">
            <div className="top-left">
              <ul className="list-style-one">
                <li>
                  <FaMobileAlt />
                  <a href="tel:9465349307" target="_blank">
                    +91-9465349307
                  </a>
                </li>
                <li>
                  <FaEnvelope />
                  <a href="mailto:newjdtourtravel@gmail.com" target="_blank">
                    newjdtourtravel@gmail.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="top-right">
              <ul className="social-icon-one">
                {socialLinks.map((link, index) => (
                  <li key={index}>
                    <a href={link.href} target="_blank">
                      {link.icon}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="header-lower">
        <div className="auto-container">
          <div className="main-box">
            <div className="logo-box">
              <div className="logo mobile-logo">
                <a href="/">
                  <img src={Img} alt="Logo" />
                </a>
              </div>
            </div>

            <div className="nav-outer">
              <nav className="nav main-menu">
                <ul className="navigation onepage-nav">
                  {navLinks.map((link, index) => (
                    <li
                      key={index}
                      className={`${
                        activeDropdown === index
                          ? "dropdown active"
                          : "dropdown"
                      }`}
                    >
                      <a
                        href={link.href}
                        onClick={(e) => {
                          e.preventDefault();
                          handleNavigation(link.href);
                        }}
                      >
                        {link.label}
                      </a>

                      <div
                        className="dropdown-btn"
                        onClick={() => toggleDropdown(index)}
                      >
                        <FaAngleDown />
                      </div>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>

            <div className="outer-box">
              <button
                className="ui-btn search-btn light"
                onClick={toggleSearch}
              >
                <FaSearch />
              </button>
              <div className="btn-box">
                <a
                  href="#contact"
                  className="theme-btn btn-style-one dark-line-two hover-light"
                >
                  <span className="btn-title">Book a Taxi</span>
                </a>
              </div>
              <div
                className="mobile-nav-toggler light"
                onClick={toggleMobileMenu}
              >
                <FaBars />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMobileMenuVisible && (
        <div className="mobile-menu open">
          <div className="menu-backdrop" onClick={toggleMobileMenu}></div>
          <nav className="menu-box">
            <div className="upper-box">
              <div className="nav-logo">
                <a href="/">
                  <img src={Img} alt="Logo" />
                </a>
              </div>
              <div className="close-btn" onClick={toggleMobileMenu}>
                <FaTimes />
              </div>
            </div>
            <ul className="navigation onepage-nav clearfix">
              {navLinks.map((link, index) => (
                <li key={index}>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavigation(link.href);
                    }}
                  >
                    {link.label}
                  </a>
                </li>
              ))}
            </ul>
            <ul className="contact-list-one light">
              {contactInfo.map((info, index) => (
                <li key={index}>
                  <i>{info.icon}</i>

                  <div className="text">
                    <span className="title">{info.title}</span>
                    <a href={info.href}>{info.text}</a>
                  </div>
                </li>
              ))}
            </ul>
            <ul className="social-links">
              {socialLinks.map((link, index) => (
                <li key={index}>
                  <a href={link.href}>{link.icon}</a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      )}

      <div
        className={`sticky-header ${
          isFixedHeader ? "fixed-header animated slideInDown" : ""
        }`}
        ref={stickyHeaderRef}
      >
        <div className="auto-container">
          <div className="inner-container">
            <div className="logo">
              <a href="/" className="title">
                <img src={Img2} alt="title" />
              </a>
            </div>
            <div className="nav-outer">
              <div
                className="mobile-nav-toggler dark"
                onClick={toggleMobileMenu}
              >
                <FaBars />
              </div>
              <nav className="main-menu">
                <ul className="navigation onepage-nav clearfix">
                  {navLinks2.map((link, index) => (
                    <li key={index}>
                      <a
                        href={link.href}
                        onClick={(e) => {
                          e.preventDefault();
                          handleNavigation(link.href);
                        }}
                      >
                        {link.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
            <div className="btn-box">
              <a
                href="#contact"
                className="theme-btn btn-style-one dark-line-two hover-light"
              >
                <span className="btn-title">Book a Taxi</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
