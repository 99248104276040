import React, { useState, useEffect } from "react";
import { FaCar, FaRoad, FaMapMarkerAlt } from "react-icons/fa";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-default.css";

const FunFactSection = () => {
  const [counts, setCounts] = useState({
    vehicles: 0,
    trips: 0,
    people: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      setCounts({
        vehicles: 32002,
        trips: 16008,
        people: 17068,
      });
    }, 1000);
  }, []);
  const counterData = [
    {
      icon: <FaCar className="icon" />,
      title: "Special Vehicles",
      count: counts.vehicles,
    },
    {
      icon: <FaRoad className="icon" />,
      title: "Road Trips Done",
      count: counts.trips,
    },
    {
      icon: <FaMapMarkerAlt className="icon" />,
      title: "People Dropped",
      count: counts.people,
    },
  ];
  return (
    <section className="funfact-section">
      <div className="auto-container">
        <div className="fact-counter">
          <div className="row">
            {counterData.map((data, index) => (
              <div className="counter-block col-lg-4 col-md-6" key={index}>
                <div className="inner-box">
                  <div className="icon-box">{data.icon}</div>
                  <div className="counter-one">
                    <Odometer value={data.count} format="d" duration={1500} />
                  </div>
                  <h4 className="counter-text">{data.title}</h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
export default FunFactSection;
