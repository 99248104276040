import React from "react";
import {
  FaMapMarkerAlt,
  FaMobileAlt,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";

const footerData = [
  {
    title: "Information",
    description:
      "At New JD Tour Travel, we provide reliable, affordable, and professional transportation services, offering everything from local rides to luxury wedding cars in Ludhiana.",
  },
  {
    title: "Contact Info",
    items: [
      {
        icon: <FaMapMarkerAlt />,
        text: "Village - Bhourla (Near Chawa), Tehsil - Samrala, Ludhiana, India, 141412",
      },
      {
        icon: <FaMobileAlt />,
        text: "+91-9465349307",
        link: "tel:9465349307",
      },
      {
        icon: <FaEnvelope />,
        text: "newjdtourtravel@gmail.com",
        link: "mailto:newjdtourtravel@gmail.com",
      },
    ],
  },
  {
    title: "Newsletter",
    description: "Signup for our weekly newsletter or updates.",
  },
];

const socialIcons = [
  {
    icon: <FaFacebook />,
    link: "https://www.facebook.com/newjdtourtravelsbhourla",
  },
  {
    icon: <FaInstagram />,
    link: "https://www.instagram.com/new_j_d_tour_travels_bhourla/",
  },
  { icon: <FaYoutube />, link: "https://www.youtube.com/@himmatdhillon7788" },
];

const Footer = () => {
  return (
    <footer className="main-footer footer-style-one">
      <div className="bg bg-image"></div>

      <div className="widgets-section">
        <div className="auto-container">
          <div className="row">
            {footerData.map((section, index) => (
              <div
                key={index}
                className={`footer-column col-lg-4 col-md-6 col-sm-12`}
              >
                <div
                  className={`footer-widget ${
                    index === 2 ? "newsletter-widget" : "about-widget"
                  }`}
                >
                  <h5 className="widget-title">{section.title}</h5>
                  <div className="widget-content">
                    {section.description && (
                      <div className="text">{section.description}</div>
                    )}
                    {section.items && (
                      <ul className="contact-list-two light">
                        {section.items.map((item, idx) => (
                          <li key={idx}>
                            <i className="fal fa-map-marker-alt">
                              {" "}
                              {item.icon}
                            </i>
                            {item.link ? (
                              <a href={item.link} target="_blank">{item.text}</a>
                            ) : (
                              item.text
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                    {index === 2 && (
                      <div className="newsletter-form">
                        <form method="post" action="#">
                          <div className="form-group">
                            <div className="input-outer">
                              <input
                                type="email"
                                name="email"
                                placeholder="Enter Your email."
                                required
                              />
                            </div>
                            <button
                              type="button"
                              className="theme-btn btn-style-one dark-line-two hover-light"
                            >
                              <span className="btn-title">Subscribe</span>
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="auto-container">
          <div className="inner-container">
            <div className="copyright-text">
              © copyright 2024 | all rights reserved
            </div>
            <div className="copyright-text">
              designed and developed{" "}
              <span>
                <a href="https://annexlogics.com/" target="_black">by AnnexLogics</a>
              </span>
            </div>
            <ul className="footer-nav">
              {socialIcons.map((social, index) => (
                <li key={index}>
                  <a href={social.link} target="_black">{social.icon}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
