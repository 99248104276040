import React from "react";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Banner1 from "../../../../images/main-slider/1.webp";
import Banner2 from "../../../../images/main-slider/2.jpg";
import OwlCarousel from "react-owl-carousel";

const sliderData = [
  {
    id: 1,
    image: Banner1,
    title: "Your Trusted Taxi Services in Ludhiana",
    subtitle:
      "At New JD Tour Travel, we take pride in being the most trusted taxi service in Ludhiana. Wherever you need to go, we are your go-to choice for dependable taxi services in Ludhiana.",
    link: "#contact",
  },
  {
    id: 2,
    image: Banner2,
    title: "Book a Luxury Car For Your Wedding!",
    subtitle:
      "Make your wedding day unforgettable by booking a luxury car from New JD Tour Travel! Arrive in style and elegance with our exquisite fleet of premium vehicles, perfect for the bride, groom, and bridal party. For a truly grand entrance or exit, book your luxury wedding car today.",
    link: "#contact",
  },
  {
    id: 3,
    image: Banner1,
    title: "Book Your Taxi Today!",
    subtitle:
      "Need a reliable ride? Book your taxi in Ludhiana today with New JD Tour Travel for fast, safe, and affordable transportation! Whether you're heading tothe airport, commuting around town, or need a late-night pickup, our professional drivers are available 24/7 to get you where you need to go.",
    link: "#contact",
  },
];

const MainSlider = () => {
  const options = {
    loop: true,
    margin: 40,
    nav: false,
    items: 1,
    center: true,
    autoplay: true,
    autoplayHoverPause: true,
    autoplayTimeout: 3000,
  };

  return (
    <section id="home" className="main-slider">
     
      <OwlCarousel className="owl-theme" {...options}>
        
        {sliderData.map((slide) => (
          <div className="item" key={slide.id}>
             <div className="tp-dottedoverlay on"></div>
            <img src={slide.image} alt="" className="rev-slidebg" />
            <div className="caption">
              <h1 className="title">{slide.title}</h1>
              <div className="text">{slide.subtitle}</div>
              <a
                href={slide.link}
                className="theme-btn btn-style-one dark-line"
              >
                <span className="btn-title">Book Now</span>
              </a>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </section>
  );
};

export default MainSlider;
