import React from "react";
import MainSlider from "../../dashboard/Home/Slider/Slider";
import ServiceSection from "../../dashboard/Home/Services/Service";
import AboutSection from "../../dashboard/Home/About/About";
import PricingSection from "../../dashboard/Home/Pricing/Pricing";
import FunFactSection from "../../dashboard/Home/Fun-Fact/FunFact";
import TestimonialSection from "../../dashboard/Home/Testimonial/Testimonial";
import NewsSection from "../../dashboard/Home/News/News";
import About1 from "../../../images/jd-tour/6.png";
import About2 from "../../../images/jd-tour/7.png";
import About3 from "../../../images/jd-tour/vdobg.png";
import About4 from "../../../images/jd-tour/1.png";
import About5 from "../../../images/jd-tour/3.png";
import Img1 from "../../../images//jd-tour/owner2.png";
import BookingSection from "../../dashboard/Home/booking/Booking";
import TourSection from "../../dashboard/Home/SpecialTour/Tour";
import FAQSection from "../../dashboard/Home/Team/Team";

const aboutSectionData = {
  title: "Welcome to New JD Tour Travel Bhourla!",
  subtitle: "About Us",
  description:
    "At New JD Tour Travel Bhourla, we are more than just a taxi service – we are your trusted transportation partner in Ludhiana. For over 10 years, we've been dedicated to providing reliable, affordable, and comfortable rides to our community. Whether you're heading to the airport, commuting locally, luxury car for wedding or  need a ride for a  special event, our professional drivers are ready to get you there safely and on  time.",
  listItems: [
    "24/7 Availability: No matter when you need a ride, we’re always on call – day or night.",
    "Affordable Rates: We believe in transparent pricing with no hidden fees, offering some of the most competitive rates in the area.",
    "Clean, Comfortable Fleet: Our cars are regularly maintained to provide you with a smooth, enjoyable ride.",
  ],
  callInfo: {
    smallText: "Call For Taxi",
    number: "9465349307",
    telLink: "tel:9465349307",
  },
  author: {
    name: "Himmat Singh Dhillon",
    designation: "Founder - CEO",
    imageSrc: Img1,
  },
  images: [About1, About2, About3],
  videoLink: "https://youtu.be/zwZl8mjIaEg?feature=shared",
};

const aboutSectionData2 = {
  heading: "Luxury Car Booking for Weddings!",
  title: "Feel the Ride of our Luxury Cars!",
  subtitle: "Arrive in Style on Your Wedding Day ",
  description:
    "Your wedding day deserves nothing but the best, and that includes your transportation. At New JD Tour Travel, we offer premium luxury car booking service  for  weddings in Ludhiana that ensure you arrive at your ceremony in style, comfort, and elegance. Whether it’s for the bride and groom, the bridal party, or distinguished guests, our luxurious vehicles add a sophisticated touch to your once-in-a-lifetime event.",
  listItems: [
    "Exquisite Luxury Fleet: Choose from a stunning selection of high-end cars, including limousines, classic cars, and luxury sedans.",
    "Professional Chauffeurs: Our chauffeurs are not only highly experienced but also trained to provide the utmost in courteous, discreet service.",
    "Bride and Groom Transportation: Arrive at your ceremony in a luxury limousine or classic car that matches the grandeur of your wedding.",
  ],
  callInfo: {
    smallText: "Call For Taxi",
    number: "9465349307",
    telLink: "tel:9465349307",
  },
  author: {
    name: "Himmat Singh Dhillon",
    designation: "Founder - CEO",
    imageSrc: Img1,
  },
  images: [About1, About2, About3],
  images2: [About4, About5, About3],
  videoLink: "https://youtu.be/zwZl8mjIaEg?feature=shared",
  videoLink2: "https://youtu.be/dkrSN_SFONw?si=OlkVUSkG7Snj4ISq",
  className: "sub-icon",
  className2: "luxury-car",
  className3: "upr-img",
};

const HomePage = () => {
  return (
    <>
      <MainSlider />
      <TourSection />
      <AboutSection
        title={aboutSectionData.title}
        className3={aboutSectionData2.className3}
        subtitle={aboutSectionData.subtitle}
        description={aboutSectionData.description}
        listItems={aboutSectionData.listItems}
        callInfo={aboutSectionData.callInfo}
        author={aboutSectionData.author}
        images={aboutSectionData.images}
        videoLink={aboutSectionData.videoLink}
      />
      <ServiceSection />
      <AboutSection
        className={aboutSectionData.className}
        className2={aboutSectionData2.className2}
        heading={aboutSectionData2.heading}
        title={aboutSectionData2.title}
        subtitle={aboutSectionData2.subtitle}
        description={aboutSectionData2.description}
        listItems={aboutSectionData2.listItems}
        callInfo={aboutSectionData2.callInfo}
        author={aboutSectionData2.author}
        images={aboutSectionData2.images2}
        videoLink={aboutSectionData2.videoLink2}
      />
      <PricingSection />
      <BookingSection />
      <FAQSection />
      <FunFactSection />
      <TestimonialSection />
      <NewsSection />
    </>
  );
};

export default HomePage;
