import React from "react";
import PageTitle from "../../dashboard/contact/pagetitle/Pagetitl";
import ContactSection from "../../dashboard/contact/contact-detail/Detail";
import MapSection from "../../dashboard/contact/location/Map";

const ContactPage = () => {
  return (
    <>
      <PageTitle />
      <ContactSection />
      <MapSection />
    </>
  );
};
export default ContactPage;
