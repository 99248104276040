import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import Header from "./tabs/dashboard/Header/Header";
import Footer from "./tabs/dashboard/Footer/Footer";
import HomePage from "./tabs/pages/Home/Home";
import ContactPage from "./tabs/pages/Contact/Contact";

function App() {
  
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}


export default App;
