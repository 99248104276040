import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FaStar, FaQuoteRight } from "react-icons/fa";
import Test1 from '../../../../images/resource/testi1-1.jpg'

const testimonials = [
  {
    id: 1,
    image: Test1,
    text: "“Excellent service! We booked a taxi for an airport drop-off, and the  driver arrived on time, the car was clean, and the ride was smooth. I highly recommend New JD Tour Travel for anyone looking for reliable transportation in the city!",
    author: "Amit P.",
    designation: "",
    rating: 5,
  },
  {
    id: 2,
    image: Test1,
    text: "“We used their luxury car rental service for our wedding, and it was perfect! The car was elegant, the chauffeur was professional, and everything went  seamlessly. Thank you, New JD Tour Travel, for making our day extra special!",
    author: "Rahul M.",
    designation: "",
    rating: 5,
  },
  {
    id: 3,
    image: Test1,
    text: "“I've used New JD Tour Travel for local rides and outstation trips,  and they never disappoint! Their drivers are courteous, always on time, and the cars are  well-maintained. They’re my go-to service whenever I need a taxi.",
    author: "Sandeep K.",
    designation: "",
    rating: 5,
  },
  {
    id: 4,
    image: Test1,
    text: "“Fantastic experience renting a car for our event! The vehicle was in excellent condition, and the service was top-notch from booking to pickup. I’ll   definitely use New JD Tour Travel again for future events.",
    author: "Pooja R.",
    designation: "",
    rating: 5,
  },
];

const TestimonialSection = () => {
  return (
    <section className="testimonial-section">
      <div className="bg bg-pattern-2"></div>
      <div className="auto-container">
        <div className="sec-title">
          <span className="sub-title">Testimonials</span>
          <h2 className="letters-slide-up text-split">Client's Reviews</h2>
        </div>
        <OwlCarousel
          className="testimonial-carousel owl-theme default-dots"
          items={1}
          loop
          nav
          margin={10}
        >
          {testimonials.map((testimonial) => (
            <div className="testimonial-block" key={testimonial.id}>
              <div className="inner-box">
                <figure className="image">
                  <img src={testimonial.image} alt="Testimonial" />
                </figure>
                <div className="content">
                  <div className="text">{testimonial.text}</div>
                  <div className="author-box">
                    <FaQuoteRight className="icon" />
                    <div className="author-info">
                      <h4 className="name">{testimonial.author}</h4>
                      <span className="designation">
                        {testimonial.designation}
                      </span>
                      <div className="rating">
                        {[...Array(testimonial.rating)].map((_, index) => (
                          <FaStar key={index} />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </section>
  );
};

export default TestimonialSection;
