import React from "react";
import img1 from "../../../../images/jd-tour/rajasthan.jpeg";
import img2 from "../../../../images/jd-tour/char-dham.jpeg";
import img3 from "../../../../images/jd-tour/jammu.jpg";
import { FaCar, FaMapMarkerAlt } from "react-icons/fa";

const TourSection = () => {
  const pricingPlans = [
    {
      id: 1,
      image: img1,
      place: "Rajasthan Tour",
      time: "November - December",
      description:
        "Discover the vibrant culture and majestic landscapes of India with New JD Tour Travel Bhourla's Rajasthan Tour, offering comfortable, guided transportation to Rajasthan's most iconic destinations. From palaces to desert safaris, experience the beauty and history of Rajasthan in style and comfort.",
    },
    {
      id: 2,
      image: img2,
      place: "Char Dham Yatra",
      time: "Anytime",
      description:
        "Embark on a spiritual journey with New JD Tour Travel Bhourla's Char Dham Yatra service, providing safe and comfortable transportation to the holy sites of Yamunotri, Gangotri, Kedarnath, and Badrinath. Let us take care of your travel needs while you focus on your sacred pilgrimage.",
    },
    {
      id: 3,
      image: img3,
      place: "Jammu Kashmir & Himachal Pradesh",
      time: "June",
      description:
        "Experience the breathtaking beauty of the Himalayas with New JD Tour Travel Bhourla's tours of Jammu & Kashmir and Himachal Pradesh, offering comfortable transportation through serene valleys and majestic mountains. Let us make your journey to these stunning regions a memorable and relaxing adventure.",
    },
  ];

  return (
    <section id="tour" className="pricing-section">
      <div className="auto-container">
        <div className="bg bg-pattern-5"></div>
        <div className="sec-title text-center">
          <span className="sub-icon"></span>
          <span className="sub-title">Plan Your Trip</span>
          <h2 className="letters-slide-up text-split">Choose The Special Tour!</h2>
        </div>
        <div className="outer-box" data-aos="fade-left">
          <div className="pricing-carousel owl-theme">
            <div className="row">
              {pricingPlans.map((plan) => (
                <div className="col-lg-4 col-sm-12 col-12" key={plan.id}>
                  <div className="pricing-block">
                    <div className="inner-box">
                      <div className="image-box">
                        <figure className="image">
                          <img src={plan.image} alt={plan.place} />
                        </figure>
                        <div className="icon-car">
                          <FaCar />
                        </div>
                      </div>
                      <div className="content">
                        <div className="car-detail">
                          <div className="city">
                            <h4 className="city-name">
                              <FaMapMarkerAlt /> {plan.place}
                            </h4>
                          </div>
                        </div>
                        <ul className="feature-list">
                          <li className="colored">Time: {plan.time}</li>
                          <li>
                            <span className="description">{plan.description}</span>
                          </li>
                        </ul>
                        <div className="btn-box">
                          <a href="#contact" className="theme-btn btn-style-two hover-light">
                            <span className="btn-title">Book Now</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TourSection;
