import React from "react";
import { FaAngleDoubleRight } from "react-icons/fa";
import Service1 from "../../../../images/jd-tour/1.png";
import Service2 from "../../../../images/jd-tour/bmw.jpeg";
import Service3 from "../../../../images/jd-tour/range-rower.jpeg";
import { FaTaxi, FaMobileAlt, FaUserTie } from "react-icons/fa";

const ServiceSection = () => {
  const services = [
    {
      title: "Airport Pickup & Drop Services",
      text: "Enjoy hassle-free airport pickup and drop services with us, offering timely, comfortable, and reliable rides to and from the airport 24/7.",
      image: Service1,
      icon: <FaTaxi />,
      link: "#contact",
    },
    {
      title: "Local Rides",
      text: "Get fast, reliable, and affordable local rides in Ludhiana, perfect for all your daily transportation needs around town.",
      image: Service2,
      icon: <FaMobileAlt />,
      link: "#contact",
    },
    {
      title: "Taxi for Outstation",
      text: "Travel comfortably beyond city limits with New JD Tour Travel's taxifor outstation services, offering safe and reliable long-distance rides atcompetitive rates.",
      image: Service3,
      icon: <FaUserTie />,
      link: "#contact",
    },
    {
      title: "Wedding Car Rental",
      text: "Make your big day even more special with our wedding car rental services, featuring elegant, luxury vehicles for a stylish and memorable  entrance.",
      image: Service1,
      icon: <FaTaxi />,
      link: "#contact",
    },
    {
      title: "Car Rental for Tour",
      text: "Try our car rental services for tours,  providing reliable, spacious vehicles for a smooth and enjoyable travel experience.",
      image: Service2,
      icon: <FaUserTie />,
      link: "#contact",
    },
    {
      title: "24 Hours Services from Delhi Airport",
      text: "Enjoy reliable and convenient 24-hour taxi services from Delhi Airport with New JD Tour Travel Bhourla, ensuring safe and timely rides anytime, day or night.",
      image: Service3,
      icon: <FaTaxi />  ,
      link: "#contact",
    },
  ];

  return (
    <section className="service-section-three" id="service">
      <div className="auto-container">
        <div className="sec-title text-center">
          <span className="sub-icon"></span>
          <h2 className="letters-slide-up text-split">Our Services</h2>
        </div>
        <div className="row" data-aos="fade-left">
          {services.map((service, index) => (
            <div className="col-lg-4 col-sm-6" key={index}>
              <div className="service-block-three">
                <div className="inner-box">
                  <div className="image-box">
                    <figure className="image">
                      <img src={service.image} alt="Service" />
                    </figure>
                    <i className="icon">{service.icon}</i>
                  </div>
                  <div className="content">
                    <h3 className="title">
                      <a href={service.link}>{service.title}</a>
                    </h3>
                    <div className="text">{service.text}</div>
                    <a href={service.link} className="icon">
                      <FaAngleDoubleRight />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
