import React from "react";
import { FaCaretRight } from "react-icons/fa";

const AboutSection = ({
  heading,
  title,
  subtitle,
  description,
  listItems,
  className1,
  className2,
  className3,
  callInfo,
  author,
  images,
  videoLink,
}) => {
  return (
    <section id="about" className="about-section ">
      <div className="auto-container">
        <div className="sec-title text-center">
          <span className={className1}></span>
          <h2 className="letters-slide-up text-split">{heading}</h2>
        </div>
        <div className="row">
          <div
            className="content-column col-lg-6 col-md-12 col-sm-12 order-lg-2"
            data-aos="fade-left"
          >
            <div className="inner-column">
              <div className="sec-title">
                <span className="sub-title">{subtitle}</span>
                <h2 className="letters-slide-up text-split">{title}</h2>
                <div className="text">{description}</div>
              </div>

              <ul className="list-style-two">
                {listItems.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>

              <div className="bottom-box">
                <a href={callInfo.telLink} className="info-btn">
                  <small>{callInfo.smallText}</small>
                  <strong>{callInfo.number}</strong>
                </a>

                <div className="author-box">
                  <div className="author-image">
                    <img src={author.imageSrc} alt="Author" />
                  </div>
                  <div className="author-info">
                    <h6 className="name">{author.name}</h6>
                    <span className="designation">{author.designation}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="image-column col-lg-6 col-md-12 col-sm-12">
            <div className="inner-column">
              <figure className="image reveal ">
                <img src={images[0]} className={className2} alt="Image 1" />
              </figure>
              <figure className="image-2 reveal">
                <img src={images[1]} alt="Image 2" />
              </figure>

              <div className="video-box" data-aos="fade-up">
                <figure className="image-3">
                  <img src={images[2]} alt="Video Thumbnail" className={className3} />
                </figure>
                <a
                  href={videoLink}
                  className="play-btn"
                  data-fancybox
                  data-caption
                  target="_blank"
                >
                  <div className="icon-triangle">
                    <FaCaretRight  />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
